import React from 'react';
import styled from 'styled-components';

import { images } from 'utils/images';
import { useRouter } from 'apis/history';
import { mobile, useQuery } from 'styles/breakpoints';
import { almostWhite, blue, white70 } from 'styles/colors';
import {
  Container,
  FlexWrapper,
  H1,
  Image,
  OutlineButton,
  Regular,
  Svg,
} from 'components';

const SymptomFree: React.FC = React.memo(() => {
  const { isMobile } = useQuery();
  const { goToQuiz } = useRouter();

  return (
    <StyledContainer>
      {isMobile ? null : (
        <LinesImage
          src="images/home/lines"
          width="1218"
          height="527"
          viewBox="0 0 1218 527"
          fill="none"
        />
      )}
      <Wrapper>
        <FlexWrapper
          alignItems="center"
          justifyContent="center"
          flexWrap="wrap"
        >
          <H1 color={almostWhite}>Enjoy</H1>
          <Image src="person_looking" width="4.375rem" margin="0 1.25rem" />
          <H1 color={almostWhite}>life</H1>
          <H1 color={almostWhite}>symptom-free</H1>
        </FlexWrapper>
        <Regular
          color={white70}
          textAlign="center"
          margin="1.625rem auto 2.5rem"
        >
          There&apos;s no one-size-fits-all when it comes to your cardiovascular
          system. Get Pulsio cardiologist care and make weakness, headache, and
          dizziness the symptoms of the past.
        </Regular>
        <OutlineButton
          maxWidth={isMobile ? '100%' : '9.5rem'}
          margin="0 auto"
          className="inverted"
          onClick={goToQuiz}
        >
          TAKE THE QUIZ
        </OutlineButton>
      </Wrapper>
    </StyledContainer>
  );
});

SymptomFree.displayName = 'SymptomFree';

export default SymptomFree;

const StyledContainer = styled(Container)`
  background-image: url(${images.lines});
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  position: relative;
  padding: 4.875rem 0 5.625rem;
  border-radius: 0.625rem;
  background: ${blue};

  @media ${mobile} {
    border-radius: 0;
    padding: 3.75rem 0 2rem;
`;
const Wrapper = styled.div`
  position: relative;
  max-width: 55rem;
  width: 100%;
  margin: 0 auto;
  padding: 0 2rem;
`;
const LinesImage = styled(Svg)`
  position: absolute;
  bottom: 0;
  left: 0;
`;
